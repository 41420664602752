
#tailored{
    height: auto;
    width: 100%;
    padding: 9em 9% 2em;
    position: relative;
    overflow: hidden;
    h2{
        font-size: 2rem;
        text-align: center;
        span{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 90%;
                right: 0;
                height: 3px;
                width: 40%;
                background-color: #F19C38;
            }
        }
    }
    .tailored-container{
        width: 100%;
        margin-top: 3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tailored-text{
            ul{
                
                li{
                    margin-bottom: 2em;
                    h3{
                        margin-bottom: .2em;
                    }
                    p{
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                ::marker{
                    color: #F19C38;
                    font-size: 1.5em;
                }
            }
        }
        .tailored-img1{
            margin-top: 2em;
            width: 100%;
            border: 1px solid #000;
            position: relative;
            z-index: 100;
            .border{
                z-index: 10;
                width: 100%;
                height: 100%;
                position: absolute;
                top: -.3em;
                left: -.3em;
                border: 1px solid #000;
            }
            img{
                width: 100%;
            }
        }
        .tailored-img2{
            border: 1px solid #000;
            margin-top: 2em;
            width: 100%;
            position: relative;
            z-index: 100;
            .border{
                z-index: 10;
                width: 100%;
                height: 100%;
                position: absolute;
                top: -.3em;
                left: -.3em;
                border: 1px solid #000;
            }
            img{
                width: 100%;
            }
        }
    }
    .circulo{
        height: 50em;
        width: 20em;
        background-color: #294892;
        position: absolute;
        bottom: -20em;
        left: -15em;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        filter: blur(200px);
        z-index: -100;
    }
}


@media only screen and (min-width: 1300px) {
    header{
        height: 8em;
        .menu{
            display: none;
        }
        .logo{
            width: 5em;
            svg{
                width: 100%;
            }
        }
        .support{
            display: flex;
            margin-left: 4%;
            cursor: pointer;
            transition: transform 200ms ease-in-out;
            &:hover{
                transform: scale(.9);
            }
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                
            }
        }
        .social-links{
            display: flex;
            svg{
                margin: .5em;
                cursor: pointer;
                transition: transform 200ms ease;
                &:hover{
                    transform: translateY(-5px);
                }
            }
        }
        nav{
            flex:1;
            position: relative;
            height: auto;
            background-color:none;
            width: auto;
            z-index: 100;
            text-transform: uppercase;
            transform: translateY(0);
            transition: transform .5s ease-in;
            .close{
                display: none;
                width: 1.5em;
                float: right;
                margin: 2em 2.1em;
                path{
                    fill: #294892;
                }    
            }
            ul{
                margin-top: 0;
                margin-left: 5%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                list-style: none;
                a{
                    color: #294892;
                    text-decoration: none;
                    margin: 0em 1.5em;
                    display: inline-block;
                    font-weight: 500;
                    position: relative;
                    &::after{
                        content: "" ;
                        position: absolute;
                        background-color: #F19C38;
                        width: 0;
                        height: 3px;
                        left: 0;
                        bottom: 15px;
                        transition: all 300ms ease-in-out;
                    }
                    &:hover::after{
                        width: 100%;
                    }
                    &:hover{
                        color: #294892;
                    }
                    
                }
            }
            
        }
    }

    #tailored{
        height: auto;
        width: 100%;
        padding: 13em 9% 5em;
        position: relative;
        h2{
            font-size: 3.5rem;
            text-align: center;
            span{
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 6px;
                    width: 40%;
                    background-color: #F19C38;
                }
            }
        }
        .tailored-container{
            margin-top: 6em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .tailored-text{
                width: 90%;
                ul{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    li{
                        width: 50%;
                        margin-bottom: 3em;
                        h3{
                            margin-bottom: .2em;
                            font-size: 1.5rem;
                        }
                        p{
                            font-size: 20px;
                            line-height: 25px;
                        }
                    }
                    ::marker{
                        color: #F19C38;
                        font-size: 2em;
                    }
                }
            }
            .tailored-img1{
                margin-top: 5em;
                width: 100%;
                position: relative;
                z-index: 100;
                border: 2px solid #000;
                .border{
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: -1em;
                    left: -1em;
                    border: 2px solid #000;
                }
                img{
                    width: 100%;
                }
            }
            .tailored-img2{
                margin-top: 5em;
                width: 100%;
                position: relative;
                z-index: 100;
                border: 2px solid #000;
                .border{
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: -1em;
                    left: -1em;
                    border: 2px solid #000;
                }
                img{
                    width: 100%;
                }
            }
        }
        .circulo{
            height: 50em;
            width: 20em;
            background-color: #294892;
            position: absolute;
            bottom: -20em;
            left: -10em;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            filter: blur(200px);
            z-index: -100;
        }
    }
}