
// SECTION1
#boid{
    height: auto;
    width: 100%;
    padding: 9em 8% 5em;
    position: relative;
    overflow: hidden;
    h2{
        font-size: 2rem;
        text-align: center;
        span{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 90%;
                right: 0;
                height: 3px;
                width: 40%;
                background-color: #F19C38;
            }
        }
    }
    .boid-container{
        margin-top: 3em;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 5em;
        padding: 0 5%;
        .boid-text{
            li{
                margin-bottom: 2em;
                h3{
                    margin-bottom: .2em;
                }
                p{
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            ::marker{
                color: #F19C38;
                font-size: 2em;
            }
            .main-btn{
                margin: 0 auto;
                margin-top: 3em;
                width: 9em;
                padding: 1em 1em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .boid-img{
            width: 50%;
            img{
                width: 100%;
            }
        }
    }
    .circulo{
        height: 50em;
        width: 20em;
        background-color: #294892;
        position: absolute;
        bottom: -20em;
        left: -20em;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        filter: blur(100px);
        z-index: -100;
    }
}

// SECTION2
#blockbase{
    height: auto;
    width: 100%;
    padding: 5em 8%;
    box-shadow: 0px 0px 128px 22px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .base-text{
        h2{
            font-size: 2rem;
            font-weight: 400;
            span{
                position: relative;
                font-weight: 700;
                
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 3px;
                    width: 50%;
                    background-color: #F19C38;
                }
            }
        }
        p{
            margin: 1em 0;
            font-size: 16px;
            line-height: 22px;
        }
        .main-btn{
            margin-top: 2em;
            width: 9em;
            padding: 1em 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F19C38;
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
            }
            .arrow{
                width: .7em;
            }
        }
    }
    .base-img{
        width: 60%;
        margin-bottom:2em ;
        img{
            width: 100%;
        }
    }
}

// SECTION3
#europechain{
    height: auto;
    width: 100%;
    padding: 5em 8%;
    background-color: #010E1E;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .europe-text{
        h2{
            color: #fff;
            font-size: 2rem;
            font-weight: 400;
            span{
                color: #fff;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    left: 0;
                    height: 3px;
                    width: 50%;
                    background-color: #F19C38;
                }
            }
        }
        p{
            color: #fff;
            margin: 1em 0;
            font-size: 16px;
            line-height: 22px;
        }
        .main-btn{
            margin-top: 2em;
            width: 9em;
            padding: 1em 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F19C38;
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
            }
            .arrow{
                width: .7em;
            }
        }
    }
    .europe-img{
        width: 80%;
        margin-bottom:2em ;
        img{
            width: 100%;
        }
    }
}

// SECTION4
#gamification{
    height: auto;
    width: 100%;
    padding: 5em 8%;
    box-shadow: 0px 0px 128px 22px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .game-text{
        h2{
            font-size: 2rem;
            font-weight: 400;
            span{
                position: relative;                
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 3px;
                    width: 40%;
                    background-color: #F19C38;
                }
            }
        }
        p{
            margin: 1em 0;
            font-size: 16px;
            line-height: 22px;
        }
        .main-btn{
            margin-top: 2em;
            width: 9em;
            padding: 1em 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F19C38;
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
            }
            .arrow{
                width: .7em;
            }
        }
    }
    .game-img{
        width: 70%;
        margin-bottom:2em ;
        img{
            width: 100%;
        }
    }

    .fullwidth{
        width:100%;
    }
}

// SECTION4
#instars{
    height: auto;
    width: 100%;
    padding: 5em 8%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .instars-text{
        h2{
            font-size: 2rem;
            font-weight: 400;
            span{
                position: relative;                
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    left: 0;
                    height: 3px;
                    width: 60%;
                    background-color: #F19C38;
                }
            }
        }
        p{
            margin: 1em 0;
            font-size: 16px;
            line-height: 22px;
        }
        .main-btn{
            margin-top: 2em;
            width: 9em;
            padding: 1em 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F19C38;
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
            }
            .arrow{
                width: .7em;
            }
        }
    }
    .instars-img{
        width: 60%;
        margin-bottom:2em ;
        img{
            width: 100%;
        }
    }
}

// SECTION5
#telos{
    height: auto;
    width: 100%;
    padding: 5em 8%;
    background-color: #010E1E;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .telos-text{
        h2{
            color: #fff;
            font-size: 2rem;
            font-weight: 400;
            span{
                color: #fff;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    left: 0;
                    height: 3px;
                    width: 60%;
                    background-color: #F19C38;
                }
            }
        }
        p{
            color: #fff;
            margin: 1em 0;
            font-size: 16px;
            line-height: 22px;
        }
        .main-btn{
            margin-top: 2em;
            width: 9em;
            padding: 1em 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F19C38;
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
            }
            .arrow{
                width: .7em;
            }
        }
    }
    .telos-img{
        width: 80%;
        margin-bottom:2em ;
        img{
            width: 100%;
        }
    }
}

// FOOTER
#footer{
    height: 57em;
    width: 100%;
    position: relative;
    padding: 2em 8% 0;
    box-shadow: 0 0 10px rgb(84, 84, 84);
    .container-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            margin: 2em 0;
        }
    }
    .footer-links{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        a{
            text-decoration: none;
            text-transform: uppercase;
            margin: 1em 0;
            font-weight: 500;
        }
    }
    .container-footer2{
        margin-top: 3em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 2em;
        .item-footer{
            h3{
                font-size: 26px;
                font-weight: 400;
                position: relative;
                display: inline-block;
                margin-bottom: .5em;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 3px;
                    width: 100%;
                    background-color: #F19C38;
        }
            }
        }
    }
}



@media only screen and (min-width: 1300px) {
    header{
        height: 8em;
        .menu{
            display: none;
        }
        .logo{
            width: 5em;
            svg{
                width: 100%;
            }
        }
        .support{
            display: flex;
            margin-left: 4%;
            cursor: pointer;
            transition: transform 200ms ease-in-out;
            &:hover{
                transform: scale(.9);
            }
            a{
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                
            }
        }
        .social-links{
            display: flex;
            svg{
                margin: .5em;
                cursor: pointer;
                transition: transform 200ms ease;
                &:hover{
                    transform: translateY(-5px);
                }
            }
        }
        nav{
            flex:1;
            position: relative;
            height: auto;
            background-color:none;
            width: auto;
            z-index: 100;
            text-transform: uppercase;
            transform: translateY(0);
            transition: transform .5s ease-in;
            .close{
                display: none;
                width: 1.5em;
                float: right;
                margin: 2em 2.1em;
                path{
                    fill: #294892;
                }    
            }
            ul{
                margin-top: 0;
                margin-left: 5%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                list-style: none;
                a{
                    color: #294892;
                    text-decoration: none;
                    margin: 0em 1.5em;
                    display: inline-block;
                    font-weight: 500;
                    position: relative;
                    &::after{
                        content: "" ;
                        position: absolute;
                        background-color: #F19C38;
                        width: 0;
                        height: 3px;
                        left: 0;
                        bottom: 15px;
                        transition: all 300ms ease-in-out;
                    }
                    &:hover::after{
                        width: 100%;
                    }
                    &:hover{
                        color: #294892;
                    }
                    
                }
            }
            
        }
    }

    #boid{
        height: auto;
        width: 100%;
        padding: 13em 8% 5em;
        position: relative;
        overflow: hidden;
        h2{
            font-size: 3.5rem;
            text-align: center;
            span{
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 6px;
                    width: 40%;
                    background-color: #F19C38;
                }
            }
        }
        .boid-container{
            margin: 6em 0;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-around;
            align-items: center;
            gap: 5em;
            padding: 0 5%;
            .boid-text{
                width: 50%;
                li{
                    margin-bottom: 2em;
                    h3{
                        margin-bottom: .2em;
                        font-size: 24px;
                    }
                    p{
                        font-size: 18px;
                        line-height: 25px;
                    }
                }
                ::marker{
                    color: #F19C38;
                    font-size: 2.5em;
                }
                .main-btn{
                    margin: 0;
                    margin-top: 3em;
                    width: 10em;
                    padding: 1.2em 1.5em;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #F19C38;
                    cursor: pointer;
                    transition: transform 200ms ease-in-out;
                    &:hover{
                        transform: scale(.9);
                    }
                    a{
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        color: #fff;
                    }
                    .arrow{
                        width: .7em;
                    }
                }
            }
            .boid-img{
                width: 25%;
                img{
                    width: 100%;
                }
            }
        }
        .circulo{
            height: 50em;
            width: 20em;
            background-color: #294892;
            position: absolute;
            bottom: -20em;
            left: 0;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            filter: blur(300px);
            z-index: -100;
        }
    }

    #blockbase{
        height: auto;
        width: 100%;
        padding: 8em 8%;
        box-shadow: 0px 0px 128px 22px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .base-text{
            width: 40%;
            h2{
                font-size: 3.5rem;
                font-weight: 400;
                span{
                    position: relative;
                    font-weight: 700;
                    
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        right: 0;
                        height: 6px;
                        width: 50%;
                        background-color: #F19C38;
                    }
                }
            }
            p{
                margin: 1em 0;
                font-size: 18px;
                line-height: 25px;
            }
            .main-btn{
                margin-top: 2em;
                width: 10em;
                padding: 1.2em 1.5em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                cursor: pointer;
                transition: transform 200ms ease-in-out;
                &:hover{
                    transform: scale(.9);
                }
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .base-img{
            width: 25%;
            margin-bottom:2em ;
            img{
                width: 100%;
            }
        }
    }

    #europechain{
        height: auto;
        width: 100%;
        padding: 8em 8%;
        background-color: #010E1E;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        .europe-text{
            width: 40%;
            h2{
                color: #fff;
                font-size: 3.5rem;
                font-weight: 400;
                span{
                    color: #fff;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        left: 0;
                        height: 6px;
                        width: 50%;
                        background-color: #F19C38;
                    }
                }
            }
            p{
                color: #fff;
                margin: 1em 0;
                font-size: 18px;
                line-height: 25px;
            }
            .main-btn{
                margin-top: 2em;
                width: 10em;
                padding: 1.2em 1.5em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                cursor: pointer;
                transition: transform 200ms ease-in-out;
                &:hover{
                    transform: scale(.9);
                }
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .europe-img{
            width: 40%;
            margin-bottom:2em ;
            img{
                width: 100%;
            }
        }
    }

    #gamification{
        height: auto;
        width: 100%;
        padding: 8em 8%;
        box-shadow: 0px 0px 128px 22px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .game-text{
            width: 40%;
            h2{
                font-size: 3.5rem;
                font-weight: 400;
                span{
                    position: relative;                
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        right: 0;
                        height: 6px;
                        width: 40%;
                        background-color: #F19C38;
                    }
                }
            }
            p{
                margin: 1em 0;
                font-size: 18px;
                line-height: 25px;
            }
            .main-btn{
                margin-top: 2em;
                width: 10em;
                padding: 1.2em 1.5em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                cursor: pointer;
                transition: transform 200ms ease-in-out;
                &:hover{
                    transform: scale(.9);
                }
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .game-img{
            width: 40%;
            margin-bottom:2em ;
            img{
                width: 100%;
            }
        }
    }

    #instars{
        height: auto;
        width: 100%;
        padding: 9em 8%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        .instars-text{
            width: 40%;
            h2{
                font-size: 3.5rem;
                font-weight: 400;
                span{
                    position: relative;                
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        left: 0;
                        height: 6px;
                        width: 60%;
                        background-color: #F19C38;
                    }
                }
            }
            p{
                margin: 1em 0;
                font-size: 18px;
                line-height: 25px;
            }
            .main-btn{
                margin-top: 2em;
                width: 10em;
                padding: 1.2em 1.5em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                cursor: pointer;
                transition: transform 200ms ease-in-out;
                &:hover{
                    transform: scale(.9);
                }
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .instars-img{
            width: 20%;
            margin-bottom:2em ;
            img{
                width: 100%;
            }
        }
    }

    #telos{
        height: auto;
        width: 100%;
        padding: 9em 8%;
        background-color: #010E1E;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        .telos-text{
            width: 40%;
            h2{
                color: #fff;
                font-size: 3.5rem;
                font-weight: 400;
                span{
                    color: #fff;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        left: 0;
                        height: 6px;
                        width: 60%;
                        background-color: #F19C38;
                    }
                }
            }
            p{
                color: #fff;
                margin: 1em 0;
                font-size: 18px;
                line-height: 25px;
            }
            .main-btn{
                margin-top: 2em;
                width: 10em;
                padding: 1.2em 1.5em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #F19C38;
                cursor: pointer;
                transition: transform 200ms ease-in-out;
                &:hover{
                    transform: scale(.9);
                }
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    color: #fff;
                }
                .arrow{
                    width: .7em;
                }
            }
        }
        .telos-img{
            width: 30%;
            margin-bottom:2em ;
            img{
                width: 100%;
            }
        }
    }

    #footer{
        height: 25em;
        width: 100%;
        position: relative;
        padding: 0 8%;
        box-shadow: 0 0 10px rgb(84, 84, 84);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .container-footer{
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            img{
                margin: 2em 0;
                align-self: flex-start;
                width: 8em;
                cursor: pointer;
            }
        }
        .footer-links{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 12em;
            a{
                text-decoration: none;
                text-transform: uppercase;
                margin: 1em 0;
                font-weight: 500;
            }
        }
        .container-footer2{
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 2em;
            .item-footer{
                h3{
                    font-size: 26px;
                    font-weight: 400;
                    position: relative;
                    display: inline-block;
                    margin-bottom: .5em;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 90%;
                        right: 0;
                        height: 3px;
                        width: 100%;
                        background-color: #F19C38;
            }
                }
            }
        }
    }
}