.infra{
    width:100vw;
    overflow-x:scroll;
    box-sizing: border-box;

        &-h1{
        margin-top:5em;
        width:100vw;
        text-align:center;

        span{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 90%;
                right: 0;
                height: 3px;
                width: 100%;
                background-color: #F19C38;
            }
        }
        }
    }

.infra-container{
  padding: 2em 0;
  width:1200px;
}

.infra-titles{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:left;
    margin:1.5em 0;
    text-align:left;
    padding: 0 2em;
    &-p{
        width:50em;
        text-align:left;
        color:black;
        font-weight:800;
    }
}

.bold{
    font-weight:800;
}

.infra-item{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:left;
    margin:1.5em 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    padding: 1.2em 2em;

    &-p{
        width:50em;
        text-align:left;
        display:flex;
        align-items: center;
    }
}




// DESKTOP

@media only screen and (min-width: 1300px) {
    .infra-container{
        padding: 5em 9%;
        width:100%;
      }
      .infra{
        width:100vw;
        overflow-x:scroll;
        box-sizing: border-box;
    
            &-h1{
            margin-top:4em;
            font-size:3.5rem;
            width:100vw;
            text-align:left;
            padding: 0 9%;

            span{
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 90%;
                    right: 0;
                    height: 6px;
                    width: 100%;
                    background-color: #F19C38;
                }
            }
        }
     }
    }